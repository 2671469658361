import React from "react";
import Img from "gatsby-image";
import styled from "@emotion/styled";
import { theme } from "../../utils/global-styles/theme";
import { Link } from "gatsby";

const LearningResourcesHero = ({
  image,
  heading,
  shortDescription,
  cardHeader,
  cardSubheader,
  popularLearningResources,
}) => {
  const HeroContainer = styled.section`
      display: flex;
      flex-direction: column;
    `,
    BackgroundImageSection = styled.div`
      display: flex;
      position: relative;
      overflow: contain;
      height: 600px;
      margin-bottom: ${popularLearningResources?.length ? '0px': '30px' };


      @media (min-width: ${theme.breakpoints.mobile}) and (max-width: ${theme
          .breakpoints.iPadPro}) {
        height: 400px;
      }

      @media only screen and (min-width: ${theme.breakpoints.iPadPro}) {
        height: 350px;
      }

    `,
    BackgroundImage = styled(Img)`
      display: block;
      position: absolute;
      top; 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 2;
      height: 100%;
      width: 100%;
    `,
    HeroData = styled.div`
      position: absolute;
      top; 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 3;
      align-self: center;
      padding-right: 40px;
      padding-left: 40px;
      padding-bottom: 40px;


      @media (min-width: ${theme.breakpoints.mobile}) and (max-width: ${theme
        .breakpoints.iPadPro}) {
          width: 80%;
    }

    @media only screen and (min-width: ${theme.breakpoints.iPadPro}) {
      width: 60%;
    }
    `,
    HeroHeader = styled.h1`
      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        padding-top: 0.3em;
      }
    `,
    HeroSubtitle = styled.p`
      font-weight: bold;
      font-size: 1.8rem;
      font-family: ${theme.fontFaces.headers};
      letter-spacing: -0.9px;
      line-height: 2.5rem;
    `,
    PopularLRsRow = styled.div`
      display: grid;
      grid-template-columns: repeat(3, 33.33%);
      min-height: 350px;
      @media only screen and (max-width: ${theme.breakpoints.helper750}) {
        display: block;
      }

      @media (min-width: ${theme.breakpoints.helper750}) and (max-width: ${theme
          .breakpoints.desktopUp}) {
        height: 350px;
      }
    `,
    PopularLRTitleCard = styled.div`
      padding: 8%;
      color: ${theme.colors.main};
      background-color: ${theme.colors.royalBlue};
      h2 {
        padding-bottom: 20px;
      }
      @media only screen and (max-width: ${theme.breakpoints.helper750}) {
        &.hide-on-mobile {
          display: none;
        }
      }
      @media (min-width: ${theme.breakpoints.tabletUp}) and (max-width: ${theme
          .breakpoints.desktopUp}) {
      }
    `,
    PopularLRCard = styled(Link)`
      display: grid;
      padding-bottom: 3%;
      grid-template: 30px 3fr 1fr 1fr / 50px 1fr 50px;
      &:hover {
        p {
          display: block;
        }
        h2 {
          display: none;
        }
      }
      @media only screen and (max-width: ${theme.breakpoints.helper750}) {
        color: ${theme.colors.main};
        &.hide-on-mobile {
          display: none;
        }
      }

      @media (min-width: ${theme.breakpoints.helper750}) and (max-width: ${theme
          .breakpoints.desktopUp}) {
        grid-template: 1em 75% 1em / 1em 1fr 1em;
      }
    `,
    LRCardTitle = styled.h2`
      grid-area: 2 / 2 / 2 / 2;
      font-size: 34px;
      @media (min-width: ${theme.breakpoints.tabletUp}) and (max-width: ${theme
          .breakpoints.desktopUp}) {
        font-size: 30px;
        line-height: 40px;
      }
    `,
    LRCardDescription = styled.p`
      grid-area: 2 / 2 / 2 / 2;
      font-size: 1.8rem;
      line-height: 1.3em;
      display: none;
      @media (min-width: ${theme.breakpoints.tabletUp}) and (max-width: ${theme
          .breakpoints.desktopUp}) {
        font-size: 1.4em;
      }
    `,
    LRCardDetails = styled.div`
      grid-area: 3 / 2 / 3 / 2;
      display: flex;
      flex-direction: row;
      justify-content: left;
      align-self: end;
      p {
        margin-right: 30px;
        font-weight: bold;
        font-family: ${theme.fontFaces.headers};
        border-top: 3px;
        border-top-style: solid;
        padding-top: 0.3em;
      }

      @media (min-width: ${theme.breakpoints.tabletUp}) and (max-width: ${theme
          .breakpoints.desktopUp}) {
        p {
          margin-right: 10px;
        }
      }
    `,
    EnrolButtonContainer = styled.div`
      grid-area: 4 / 2 / 4 / 2;
      display: flex;
      align-self: center;
      justify-content: flex-end;
      margin-right: 5px;
      a {
        border-bottom: 4px solid;
        cursor: pointer;
        color: ${theme.colors.black};
      }
    `,
    MobileLRSection = styled.div`
      display: none;
      @media only screen and (max-width: ${theme.breakpoints.helper750}) {
        display: block;
        padding-top: 30px;

        div {
          position: relative;
          margin-bottom: 40px;
          margin-left: 12px;
          top: 5px;
          font-size: 32px;
          font-weight: bold;
          font-family: ${theme.fontFaces.headers};
        }
      }
    `;
  return (
    <HeroContainer>
      <BackgroundImageSection>
        <BackgroundImage fluid={image} />
        <HeroData>
          <HeroHeader className="codedH1 white">{heading}</HeroHeader>
          <HeroSubtitle className="white">{shortDescription}</HeroSubtitle>
        </HeroData>
      </BackgroundImageSection>
      {popularLearningResources?.length && (
        <MobileLRSection>
          <div>{cardHeader}</div>
        </MobileLRSection>
      )}
      {popularLearningResources?.length && (
        <PopularLRsRow>
          <PopularLRTitleCard className="hide-on-mobile">
            <h2>{cardHeader}</h2>
            <p>{cardSubheader}</p>
          </PopularLRTitleCard>
          <PopularLRCard
            to={`/course/${popularLearningResources[0].slug}`}
            className="course-catalog-hero-primary-card"
          >
            <LRCardTitle>{popularLearningResources[0].title}</LRCardTitle>
            <LRCardDescription>
              {popularLearningResources[0].shortDescription}
            </LRCardDescription>
            <LRCardDetails>
              <p>{popularLearningResources[0].duration}</p>
            </LRCardDetails>
            <EnrolButtonContainer>
              <Link to={`/`}>Enroll Now</Link>
            </EnrolButtonContainer>
          </PopularLRCard>
          <PopularLRCard
            to={`/course/${popularLearningResources[1].slug}`}
            className="hide-on-mobile course-catalog-hero-secondary-card"
          >
            <LRCardTitle>{popularLearningResources[1].title}</LRCardTitle>
            <LRCardDescription>
              {popularLearningResources[1].shortDescription}
            </LRCardDescription>
            <LRCardDetails>
              <p>{popularLearningResources[1].duration}</p>
            </LRCardDetails>
            <EnrolButtonContainer>
              <Link to={`/`}>Enroll Now</Link>
            </EnrolButtonContainer>
          </PopularLRCard>
        </PopularLRsRow>
      )}
    </HeroContainer>
  );
};

export default LearningResourcesHero;
