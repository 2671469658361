import Airtable from "airtable";

const base = new Airtable({ apiKey: process.env.GATSBY_AIRTABLE_API_KEY }).base(
  process.env.GATSBY_AIRTABLE_BASE_ID
);

const logSearch = async (searchQuery, email = null) => {
  try {
    const timestamp = new Date().toISOString();
    base("Logs").create([
      {
        fields: {
          "Search Query": searchQuery,
          User: email,
        },
      },
    ]);
  } catch (error) {
    console.error("Error adding search log:", error);
  }
};

export default logSearch;
